.nav-menu-logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 60px;
    width: 60%;
    padding: 8px;
}

ion-menu ion-header {
    margin-top: env(safe-area-inset-top) !important;
}
